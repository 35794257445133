import React, { useState, useEffect, useRef } from "react";
import { useUserMedia } from "./hooks/use-user-media";
import { useAnimationFrame } from "./hooks/use-animation-frame";
import { videoToCanvasDimensions } from "./lib/video-to-canvas-dimensions";
import * as detector from "./lib/detector";
import * as audio from "./lib/audio";
import "../styles/intro.css";
import { stepInterval } from "./lib/utils";
import * as boundingBox from "./lib/bounding-box";
import { getColor } from "./lib/colors";
import { objectClasses } from "./lib/object-classes";
import * as HistoryCircle from "./lib/history-circle";

const DEBUG = false;

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "environment" },
};

type VideoProps = {
  startDetection: boolean;
};

function Video({ startDetection }: VideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [time, setTime] = useState(0);
  const [detectionName, setDetectionName] = useState("");
  const [historyCircles, setHistoryCircles] = useState<
    HistoryCircle.HistoryCircle[]
  >([]);
  const [detectionBoundingBox, setDetectionBoundingBox] = useState({
    x: 100,
    y: 100,
    width: 100,
    height: 100,
  });
  const [isModelLoaded, setModelLoaded] = useState(false);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  // Load the ML model
  useEffect(() => {
    detector.loadModel().then(() => {
      setModelLoaded(true);
    });
  }, []);

  // Update canvas
  if (canvasRef && canvasRef.current && videoRef && videoRef.current) {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const canvasDimensions = videoToCanvasDimensions(
      video.videoWidth,
      video.videoHeight,
      canvas.width,
      canvas.height
    );
    const { xOffset, yOffset, width, height, scale } = canvasDimensions;

    const context = canvas.getContext("2d");
    if (context) {
      context.drawImage(videoRef.current, xOffset, yOffset, width, height);

      if (detectionBoundingBox && startDetection) {
        boundingBox.draw(
          detectionBoundingBox,
          context,
          video,
          canvasDimensions,
          canvas.width,
          canvas.height
        );
      }

      const horizontalCenter = canvas.width * 0.5;
      const maxDiameter = Math.min(canvas.width, canvas.height) * 0.2;
      historyCircles.forEach((historyCircle) => {
        HistoryCircle.draw(
          context,
          historyCircle,
          time,
          horizontalCenter,
          maxDiameter,
          maxDiameter
        );
      });
    }
  }

  function handleCanPlay() {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
      audio.setupLoop();
    }
  }

  // Force regular updates
  useAnimationFrame((t: number) => {
    setTime(t);

    if (isModelLoaded && startDetection) {
      // only call detection every 0.1 seconds
      if (stepInterval(t, 100, 0, 15)) {
        detectObject();
      }
    }
  });

  // detect object && play audio -- could use some clean-up
  function detectObject() {
    let object = detector.getDetection(videoRef.current as HTMLVideoElement);

    if (object.boundingBox) {
      setDetectionBoundingBox(
        boundingBox.create(detectionBoundingBox, object.boundingBox)
      );
    }

    if (object.name !== detectionName) {
      setDetectionName(object.name);
      audio.playObjectAudio(object.name);

      setHistoryCircles([
        ...historyCircles.filter((historyCircle) =>
          HistoryCircle.isAlive(historyCircle, time)
        ),
        HistoryCircle.create(time, object),
      ]);

      const objectId = objectClasses.keys.indexOf(object.name);
      const color = getColor(objectId);

      boundingBox.setColor(color);

      if (DEBUG) {
        console.log("Detected: ", object.name);
        console.log(JSON.stringify(object.boundingBox));
      }
    }
  }

  if (!mediaStream) {
    return null;
  }

  return (
    <div>
      <canvas className="VideoCanvas" ref={canvasRef} />
      <video
        autoPlay
        playsInline
        muted
        ref={videoRef}
        onCanPlay={handleCanPlay}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default Video;
