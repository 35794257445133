import React, { useRef } from "react";

export const useAnimationFrame = (callback: (time: number) => any) => {
  const callbackRef = React.useRef(callback);
  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  const loop = (time: number) => {
    frameRef.current = requestAnimationFrame(loop);
    const cb = callbackRef.current;
    cb(time);
  };
  const frameRef = React.useRef<number>();
  React.useLayoutEffect(() => {
    frameRef.current = requestAnimationFrame(loop);
    return () => {
      frameRef.current && cancelAnimationFrame(frameRef.current);
    };
  }, []);
};
