export function lerp(a, b, t) {
  return a + (b - a) * t;
}

export function fromRange(x, min, max) {
  let t = 0;

  // Divide by Zero check
  if (min !== max) {
    t = (x - min) / (max - min);
  }

  return t;
}

export function rangeMap(x, inMin, inMax, outMin, outMax) {
  let t = fromRange(x, inMin, inMax);

  return lerp(outMin, outMax, t);
}

export function stepInterval(time, step, min, max) {
  let t = time % step;
  return t >= min && t <= max;
}
