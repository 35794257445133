import React, { useState, useEffect } from "react";
import * as StartAudioContext from "startaudiocontext";
import * as Tone from "tone";
import { audioSampleNotations } from "./audio-sample-notation";
import { objectAudioSamples } from "./object-audio-samples";

let audioContextStarted = false;
let samplesLoaded = false;
let sampler;
let loopTrack;
let loopCount = Infinity;
const instruments = {};

const DEBUG = false;

function log(message) {
  if (DEBUG) {
    console.log(message);
  }
}

export function start() {
  log("startAudio()");
  startAudioContext();
}

export function playObjectAudio(object) {
  log("playObjectAudio(): " + object);

  let sample = objectAudioSamples[object];
  let note = audioSampleNotations[sample];
  log("Played " + object + ": (Sample: " + sample + ", Note: " + note + ")");
  sampler.triggerAttack(note, "@4n");
}

export function loadSamples(callback) {
  if (samplesLoaded === true) return;

  log("loadSamples()");
  // create houseparty sampler
  let houseparty = new Tone.Sampler(
    {
      C1: "audio/houseparty/SS-HouseParty-01.mp3",
      D1: "audio/houseparty/SS-HouseParty-02.mp3",
      E1: "audio/houseparty/SS-HouseParty-03.mp3",
      F1: "audio/houseparty/SS-HouseParty-04.mp3",
      G1: "audio/houseparty/SS-HouseParty-05.mp3",
      A2: "audio/houseparty/SS-HouseParty-06.mp3",
      B2: "audio/houseparty/SS-HouseParty-07.mp3",
      C2: "audio/houseparty/SS-HouseParty-08.mp3",
      D2: "audio/houseparty/SS-HouseParty-09.mp3",
      E2: "audio/houseparty/SS-HouseParty-10.mp3",
      F2: "audio/houseparty/SS-HouseParty-11.mp3",
      G2: "audio/houseparty/SS-HouseParty-12.mp3",
      A3: "audio/houseparty/SS-HouseParty-13.mp3",
      B3: "audio/houseparty/SS-HouseParty-14.mp3",
      C3: "audio/houseparty/SS-HouseParty-15.mp3",
      D3: "audio/houseparty/SS-HouseParty-16.mp3",
      E3: "audio/houseparty/SS-HouseParty-17.mp3",
    },
    function () {
      log("Samples Loaded");
      samplesLoaded = true;
      instruments.houseparty = houseparty;
      setInstrument(instruments.houseparty);
      callback();
    }
  ).toMaster();
}

export function playIntroAudio() {
  log("playIntroAudio()");

  let reverb = new Tone.Reverb().toMaster();
  reverb.generate();
  reverb.decay = 4;

  let synth = new Tone.Synth().connect(reverb);
  var polySynth = new Tone.PolySynth(7, Tone.Synth);
  polySynth.connect(reverb);

  let introNotes = ["C4", "D4", "E4", "G4", "A4", "C5"];
  let introNoteLoopCount = 4;
  let introPattern = [];

  for (let i = 0; i < introNoteLoopCount; i++) {
    for (let j = 0; j < introNotes.length; j++) {
      introPattern.push(introNotes[j]);
    }
  }

  let i = 0;
  let pattern = new Tone.Pattern(
    function (time, note) {
      synth.triggerAttackRelease(note, "8n", "@4n");
      progress(i, time);
      i++;
    },
    introPattern,
    "up"
  );

  Tone.Transport.bpm.value = 600;
  Tone.Transport.start();
  pattern.iterations = introPattern.length;
  pattern.start(0);

  function progress(i, time) {
    if (i < 23) {
      return;
    }
    polySynth.triggerAttackRelease(
      ["C3", "E3", "G3", "C2", "E2", "C1"],
      0.425, // duration
      time + 0.335, // when note shoud play
      2 //velocity
    );
  }
}

function startAudioContext() {
  if (audioContextStarted === true) return;

  StartAudioContext(Tone.context).then(function () {
    log("Audio context started");
    audioContextStarted = true;
  });
}

function setInstrument(instrument) {
  sampler = instrument;
}

export function setupLoop() {
  log("setupLoop()");
  loopTrack = new Tone.Loop(function (time) {
    sampler.triggerAttack("C1");
  });

  loopTrack.interval = "2m";
  loopTrack.iterations = loopCount;
  Tone.Transport.bpm.value = 125;
  Tone.Transport.start();
  startLoopTrack();
}

function startLoopTrack() {
  log("startLoopTrack");
  loopTrack.start();
}

function stopLoopTrack() {
  log("cancelLoopTrack");
  loopTrack.stop();
}
