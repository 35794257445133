export const objectClasses = {
  keys: [
    "cat",
    "dog",
    "person",
    "car",
    "backpack",
    "handbag",
    "bottle",
    "wine glass",
    "cup",
    "fork",
    "spoon",
    "knife",
    "bowl",
    "banana",
    "apple",
    "sandwich",
    "orange",
    "broccoli",
    "carrot",
    "hot dog",
    "pizza",
    "chair",
    "couch",
    "potted plant",
    "bed",
    "dining table",
    "toilet",
    "tv",
    "laptop",
    "mouse",
    "keyboard",
    "cell phone",
    "book",
    "clock",
  ],
};
