// detection.ts
// Module for

import { objectClasses } from "./object-classes";
import { getColor } from "./colors";
import { IDetectedObject } from "./detector";
import { circle } from "./draw";

const DEBUG: boolean = true;

function log(message: any) {
  if (DEBUG) {
    console.log(message);
  }
}

export interface HistoryCircle {
  name: string;
  born: number;
  color: string;
}

export const create = (
  time: number,
  object: IDetectedObject
): HistoryCircle => {
  const objectId = objectClasses.keys.indexOf(object.name);
  const color = getColor(objectId);

  return {
    name: object.name,
    born: time,
    color: color,
  };
};

function easeOutExpo(x: number): number {
  // Source: https://easings.net/#easeOutExpo
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}

const GROWTH_DURATION = 1000;
const HOLD_DURATION = 10000;
const LIFETIME = GROWTH_DURATION + HOLD_DURATION;

export const getAge = (detection: HistoryCircle, time: number) => {
  return time - detection.born;
};

export const isAlive = (detection: HistoryCircle, time: number) => {
  return getAge(detection, time) <= LIFETIME;
};

export const isFullyGrown = (detection: HistoryCircle, time: number) => {
  return GROWTH_DURATION <= getAge(detection, time);
};

export const draw = (
  context: CanvasRenderingContext2D,
  detection: HistoryCircle,
  time: number,
  x: number,
  y: number,
  diameter: number
) => {
  const age = getAge(detection, time);
  if (!isAlive(detection, time)) {
    return;
  }

  const growthProgress = Math.min(age / GROWTH_DURATION, 1.0);
  const d = growthProgress * diameter;

  circle(context, detection.color, x, y, d);
};
