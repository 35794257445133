export const objectAudioSamples = {
  cat: "2",
  dog: "2",
  person: "2",
  car: "3",
  backpack: "3",
  handbag: "3",
  bottle: "2",
  "wine glass": "3",
  cup: "3",
  fork: "4",
  spoon: "4",
  knife: "5",
  bowl: "5",
  banana: "5",
  apple: "6",
  sandwich: "6",
  orange: "7",
  broccoli: "7",
  carrot: "8",
  "hot dog": "8",
  pizza: "9",
  chair: "9",
  couch: "10",
  "potted plant": "11",
  bed: "12",
  "dining table": "12",
  toilet: "13",
  tv: "13",
  laptop: "14",
  mouse: "14",
  keyboard: "15",
  "cell phone": "16",
  book: "17",
  clock: "17",
};
