import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";
import "../styles/intro.css";
import Video from "./video";
// import Webcam from "./webcam";

function FinalPage() {
  //THIS IS THE FINAL PAGE

  //LOAD OBJECT 1: (TODO: CHANGE ZIGZAG.JSON TO LYLIAN NEW JSON)
  const Object1 = useRef(null);
  useEffect(() => {
    Lottie.loadAnimation({
      container: Object1.current,
      renderer: "svg",
      autoplay: true,
      animationData: require("../animations/zigzag.json"),
      loop: true,
    });
  });
  //LOAD OBJECT 2: (TODO: CHANGE ZIGZAG.JSON TO LYLIAN NEW JSON)
  const Object2 = useRef(null);
  useEffect(() => {
    Lottie.loadAnimation({
      container: Object2.current,
      renderer: "svg",
      autoplay: true,
      animationData: require("../animations/zigzag.json"),
      loop: true,
    });
  });
  return (
    <div className="Instructions">
      <div className="Instructionswrapper">
        <div className="Objects" ref={Object1}></div>
        <div className="Objects" ref={Object2}></div>
      </div>
      <Video />
    </div>
  );
}

export default FinalPage;
