const LINE_WIDTH = 8;

export function rectangle(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number
) {
  // Used to draw stroke on center of shape
  const HALF_LINE_WIDTH = LINE_WIDTH * 0.5;

  // Draw Rectangle
  context.beginPath();
  context.rect(
    x + HALF_LINE_WIDTH,
    y + HALF_LINE_WIDTH,
    width - LINE_WIDTH,
    height - LINE_WIDTH
  );
  context.lineWidth = LINE_WIDTH;
  context.stroke();
}

export function rectangleCentered(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number
) {
  return rectangle(context, x - width * 0.5, y - height * 0.5, width, height);
}

export function circle(
  context: CanvasRenderingContext2D,
  color: string,
  x: number,
  y: number,
  diameter: number
) {
  context.beginPath();

  const rotation = 0;
  const startAngle = 0;
  const endAngle = Math.PI * 2;
  context.ellipse(x, y, diameter, diameter, rotation, startAngle, endAngle);
  context.fillStyle = color;
  context.fill();
}

export function ellipse(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number
) {
  if (!context) {
    return;
  }

  const pointCount = 200;
  const PI2 = Math.PI * 2;
  let points = [];

  const time = Date.now() / 1000;
  for (let i = 0; i < pointCount; i++) {
    const t = (i / pointCount) * PI2;
    const xRad = width * 0.5;
    const yRad = height * 0.5;
    const point: Point = {
      x: x + Math.cos(t) * xRad,
      y: y + Math.sin(t) * yRad,
    };

    // Sine Displacement
    const freq = 12;
    const amplitude = 0.05;
    let displace = Math.sin((t + time) * freq) * amplitude;
    displace +=
      Math.sin((t + 1212 - time * 0.5) * freq * 0.5) * amplitude * 0.5;
    displace +=
      Math.sin((t + 1231234 - time * 0.25) * freq * 0.25) * amplitude * 0.25;

    point.x += (point.x - x) * displace;
    point.y += (point.y - y) * displace;

    points.push(point);
  }

  context.beginPath();
  context.moveTo(points[0].x, points[0].y);
  for (let i = 1; i <= points.length; i++) {
    const point = points[i % points.length];
    context.lineTo(point.x, point.y);
  }

  context.closePath();
  context.lineWidth = LINE_WIDTH + Math.sin(time * PI2 * 0.2) * 2;
  context.stroke();
}

interface Point {
  x: number;
  y: number;
}
