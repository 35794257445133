import React, { useState, useEffect } from "react";
import { useForm, useStep } from "react-hooks-helper";
import IntroDesktop from "./intro-desktop";
import IntroMobile from "./intro-mobile";
import Intructions from "./instructions";
import FinalPage from "./final-page";
import { isMobile } from "react-device-detect";
import * as audio from "./lib/audio";

//TODO: https://www.npmjs.com/package/react-device-detect - DETECT MOBILE OR DESK USER
//bind the props of the button to the steps.

const steps = [{ id: "intro" }, { id: "intructions" }, { id: "final" }];

const Router = () => {
  const { step, navigation } = useStep({
    initialStep: 0,
    steps,
  });
  const { id } = step;
  const props = { navigation };

  useEffect(() => {
    audio.loadSamples();
  }, [id]);

  switch (id) {
    case "intro":
      return isMobile ? (
        <IntroMobile {...props} />
      ) : (
        <IntroDesktop {...props} />
      );
    case "intructions":
      return <Intructions {...props} />;
    case "final":
      return <FinalPage {...props} />;
    default:
      return null;
  }
};

export default Router;
