import React, { useRef, useState, useEffect } from "react";
import "../styles/intro.css";
import Video from "./video";
import Lottie from "lottie-web";

function Instructions({ navigation }) {
  //THIS IS THE INTUCTIONS PAGE
  const container = useRef(null);
  const [showInstructions, setShowInstructions] = useState(true);
  const { next } = navigation;

  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: require("../animations/zigzag.json"),
      loop: true,
    });
  });

  const hideInstructions = () => {
    setShowInstructions(false);
  };

  const renderInstructions = () => {
    return (
      <div className="Instructionswrapper">
        <h1 className="TitleIntructions">
          Find these objects
          <br />
          to play a tone!
        </h1>
        <div className="linecontainer" ref={container}></div>
        <ol>
          <li>Keyboard</li>
          <li>Mouse</li>
          <li>Monitor</li>
          <li>Banana</li>
          <li>Plant</li>
          <li>Glasses</li>
          <li>Book</li>
          <li>Phone</li>
          <li>+more!</li>
        </ol>
        <div className="Button" onClick={hideInstructions}>
          Let me play!
        </div>
      </div>
    );
  };

  const renderVideoUI = () => {
    return <div></div>;
  };

  return (
    <div className="Instructions">
      {showInstructions ? renderInstructions() : renderVideoUI()}
      <Video className="InstructionsBg" startDetection={!showInstructions} />
    </div>
  );
}

export default Instructions;
