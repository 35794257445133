import React, { useEffect, useRef } from "react";
import "../styles/intro.css";

function IntroMobile({ navigation }) {
  //THIS IS THE INTRO FOR MOBILE ACCESS

  const { next } = navigation;

  return (
    <div className="wrapperMobile">
      <div className="QrWrapper">
        <h1>Banana Tone</h1>
        <div className="Button" onClick={next}>
          Start
        </div>
        <p className="welcomeText">
          Welcome to the BananaTone.
          <br />A ML app that let you create music
          <br />
          with objects around you!
        </p>
      </div>
    </div>
  );
}

export default IntroMobile;
