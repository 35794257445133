export const colorPalette = [
  "#286ec6",
  "#ebba31",
  "#cb4b3e",
  "#2013b8",
  "#5212a6",
  "#c90487",
  "#56990a",
  "#085e50",
  "#e2c913",
  "#cae5ec",
  "#d9f587",
  "#dc000b",
  "#8c12a3",
  "#32019b",
];

export function getColor(id) {
  return colorPalette[id % colorPalette.length];
}

export function getRandomColor() {
  // Source: https://stackoverflow.com/a/1484514

  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function pickColor() {
  var color = colorPalette[Math.floor(Math.random() * colorPalette.length)];

  return color;
}
