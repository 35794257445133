export function videoToCanvasDimensions(
  videoWidth: number,
  videoHeight: number,
  canvasWidth: number,
  canvasHeight: number
): IVideoCanvasDimensions {
  const videoAspect = videoWidth / videoHeight;
  const canvasAspect = canvasWidth / canvasHeight;

  let scale = 1.0;

  // Video is wider than canvas
  if (videoAspect > canvasAspect) {
    scale = canvasHeight / videoHeight;
  } else {
    scale = canvasWidth / videoWidth;
  }

  let width = videoWidth * scale;
  let height = videoHeight * scale;
  let xOffset = (canvasWidth - width) * 0.5;
  let yOffset = (canvasHeight - height) * 0.5;

  return { xOffset, yOffset, width, height, scale } as IVideoCanvasDimensions;
}

export interface IVideoCanvasDimensions {
  xOffset: number;
  yOffset: number;
  width: number;
  height: number;
  scale: number;
}
