import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";
import * as audio from "./lib/audio";
import "../styles/intro.css";
import qrcode from "../images/qrcode.png";
import logo from "../images/logo.png";

function IntroDesktop({ navigation }) {
  // INTRO AUDIO
  console.log("IntroDesktop");
  audio.playIntroAudio();

  //THIS IS THE INTRO FOR DESKTOP ACCESS
  const container = useRef(null);
  const { next } = navigation;
  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: require("../animations/intro.json"),
      loop: false,
    });
  });

  return (
    <div className="wrapper">
      <div className="QrWrapper">
        <h1>Banana Tone</h1>
        <img src={qrcode} alt="Replace this qrcode in production" />
        <p className="warning">
          This experience is cooler on mobile.
          <br />
          Point your cellphone at this 👆 QR code
        </p>
        <div className="ButtonDesk Button" onClick={next}>
          Try on Desktop
        </div>
      </div>
      <div className="container" ref={container}></div>
    </div>
  );
}

export default IntroDesktop;
