export const audioSampleNotations = {
  "1": "C1",
  "2": "D1",
  "3": "E1",
  "4": "F1",
  "5": "G1",
  "6": "A2",
  "7": "B2",
  "8": "C2",
  "9": "D2",
  "10": "E2",
  "11": "F2",
  "12": "G2",
  "13": "A3",
  "14": "B3",
  "15": "C3",
  "16": "D3",
  "17": "E3",
};
