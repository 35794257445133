import { IBoundingBox } from "./detector";
import { pickColor } from "./colors";
import { ellipse, rectangle, rectangleCentered } from "./draw";
import { lerp } from "./utils";
import { IVideoCanvasDimensions } from "./video-to-canvas-dimensions";

const DEBUG = false;

export const create = (
  oldBox: IBoundingBox,
  newBox: IBoundingBox,
  smoothFactor = 0.45
): IBoundingBox => {
  const t = 1.0 - smoothFactor;

  // return {
  //   x: lerp(oldBox.x, newBox.x, t),
  //   y: lerp(oldBox.y, newBox.y, t),
  //   width: lerp(oldBox.width, newBox.width, t),
  //   height: lerp(oldBox.height, newBox.height, t),
  // } as IBoundingBox;

  return {
    x: newBox.x,
    y: newBox.y,
    width: newBox.width,
    height: newBox.height,
  } as IBoundingBox;
};

export const draw = (
  box: IBoundingBox,
  context: CanvasRenderingContext2D,
  video: HTMLVideoElement,
  convertedDims: IVideoCanvasDimensions,
  canvasWidth: number,
  canvasHeight: number
) => {
  const {
    x: screenX,
    y: screenY,
    width: screenWidth,
    height: screenHeight,
  } = calculateScreenPosition(box, convertedDims, video);

  if (DEBUG) {
    // Video
    context.strokeStyle = "red";
    rectangle(context, 0, 0, video.videoWidth, video.videoHeight);

    // Canvas
    context.strokeStyle = "green";
    rectangle(context, 0, 0, canvasWidth, canvasHeight);

    // Box in Video Space
    context.strokeStyle = "blue";
    rectangleCentered(context, box.x, box.y, box.width, box.height); // TrackedBox

    // Box in Canvas Space
    context.strokeStyle = "magenta";
    rectangleCentered(context, screenX, screenY, screenWidth, screenHeight); // TrackedBox
  }

  // Draw Squiggle
  context.strokeStyle = boxColor;
  ellipse(context, screenX, screenY, screenWidth, screenHeight);
};

const calculateScreenPosition = (
  box: IBoundingBox,
  convertedDims: IVideoCanvasDimensions,
  video: HTMLVideoElement
) => {
  let { x, y, width, height } = box;
  let { xOffset, yOffset, scale } = convertedDims;

  return {
    x: x * scale + xOffset,
    y: y * scale + yOffset,
    width: width * scale,
    height: height * scale,
  } as IBoundingBox;
};

let boxColor: string;
export const setColor = (color: string) => {
  boxColor = color;
};
